import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { HttpClient, provideHttpClient } from "@angular/common/http";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth, Auth } from "@angular/fire/auth";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { provideRemoteConfig, getRemoteConfig } from "@angular/fire/remote-config";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { provideAngularSvgIcon } from "angular-svg-icon";
import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/shared/translate/custom.translate.loader";
import * as Sentry from '@sentry/angular';
import { provideIcons } from "@ng-icons/core";
import { ICONS, NGICONS } from "src/icons";
import { LucideAngularModule } from "lucide-angular";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideToastr } from "ngx-toastr";
import { pmsRoutes } from "./pms-routing";
import { Formio, FormioAppConfig } from '@formio/angular';
import premium from '@formio/premium';
import tailwind from "@tsed/tailwind-formio";

Formio.license = 'eyJhbGciOiJQUzI1NiJ9.eyJsaWNlbnNlTmFtZSI6IlZFVCBBSSBMaWJyYXJ5IExpY2Vuc2UgdGhyb3VnaCAwMS8xOC8yMDI2IiwiaWF0IjoxNzM5NTA5MjAwLCJleHAiOjE3Njg3NTU2MDAsImZhaWxEYXRlIjoxNzcyMjk4MDAwLCJ0ZXJtcyI6eyJwcmVtaXVtIjp0cnVlLCJvZmZsaW5lIjp0cnVlLCJlbnRlcnByaXNlQnVpbGRlciI6ZmFsc2UsInJlcG9ydGluZyI6ZmFsc2UsInNxbGNvbm5lY3RvciI6ZmFsc2UsImVuZHBvaW50cyI6WyIqLnZldC1haS5jb20iLCIqLmpvaWlwZXRjYXJlLmNvbSIsIioudmVyY2VsLmFwcCJdLCJob3N0bmFtZXMiOlsiKi52ZXQtYWkuY29tIiwiKi5qb2lpcGV0Y2FyZS5jb20iLCIqLnZlcmNlbC5hcHAiXX0sImlzcyI6Imh0dHBzOi8vZm9ybS5pbyIsInN1YiI6IkZvcm0uaW8ifQ.sjqpzSrPcRvhX4gs04FW16QLMZVZ3DMjc42gOKUkXsQcuMf-8Ntu8w3co_V2f3_ZvzPkywz-i1pvOUt_DFNxgNWKhM_CIgZLG7UJoy6043AdZRx23fHd-EwSTdrqDtesUkWeECuBGYl9gQ6CjZwYg9fzg4xXplB61SL2zkaWCXev3bpgqCg6YyxMIrRmbS5XqwlC3Zuz-0jg0vtL8hy3MRX5XH2LhuuowrBVDzOJw6H3zkBFjoY4YoOgrkG6Fjttm1YRpwMZe7JngsEtaffKo1vsX23x1Ttjm3RsDyrpC_2qLpPswmkK1oQVWCrDgbD1Po9i6RCiFtLGZPynvvQKGw';
Formio.use(premium);
Formio.use(tailwind);
(Formio as any).icons = 'ionicons';

export const pmsConfig: ApplicationConfig = {
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
        provideRemoteConfig(() => getRemoteConfig()),
        provideHttpClient(),
        provideAngularSvgIcon(),
        provideIcons(NGICONS),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideToastr({ timeOut: 10000, positionClass: 'toast-bottom-center' }),
        importProvidersFrom(LucideAngularModule.pick(ICONS)),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        // This ensures auth is ready to rock and roll before any component is created
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: Auth) => () => new Promise((resolve) => {
                auth.onAuthStateChanged((user) => {
                    // Firebase is ready, regardless of user being logged in or not
                    resolve(true);
                });
            }),
            deps: [Auth],
            multi: true,
        },
        importProvidersFrom(TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },

        })),
        {
            provide: FormioAppConfig,
            useValue: new FormioAppConfig({ baseUrl: environment.formIoRootUrl }),
        },
        provideRouter(pmsRoutes)
    ]
}