import { Routes } from '@angular/router';
import { OnboardingGuard } from 'src/shared/guards/onboarding.guard';

export const onboardingRoutes: Routes = [
  {
    path: 'onboarding',
    loadComponent: () => import('./onboarding/onboarding.component').then(x => x.OnboardingComponent),
    canActivate: [OnboardingGuard],
    children: [
      {
        path: 'staff',
        loadComponent: () => import('./staffonboarding/staffonboarding.component').then(x => x.StaffOnboardingComponent),
        title: 'Joii PMS - Staff Onboarding'
      }
    ],
  }
];