import { Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';

export const dashboardRoutes: Routes = [
  {
    path: "dashboard",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/dashboard/dashboard.component').then(x => x.DashboardComponent),
    title: 'Joii PMS - Dashboard'
  },
  {
    path: "submission/:id",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/submissionHistory/submission-history.component').then(x => x.SubmissionHistoryComponent),
    title: 'Joii PMS - Submission History'
  },
  {
    path: "videocheck",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/videochecks/videochecks.component').then(x => x.VideoChecksComponent),
    title: 'Joii PMS - Video Check'
  }
];