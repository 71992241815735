import { Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';
import { onboardingRoutes } from './onboarding/onboarding-routing';
import { dashboardRoutes } from './dashboard/dashboard-routing';
import { consultationRoutes } from './consultations/consultations-routing';
import { calendarRoutes } from './calendar/calendar-routing';
import { appointmentsRoutes } from './appointments/appointments-routing';
import { accountRoutes } from './account/account-routing';

export const pmsRoutes: Routes = [
    { path: 'checkaccess', loadComponent: () => import('src/shared/components/authentication/checkstaffaccess/checkstaffaccess.component').then(x => x.CheckStaffAccessComponent), canActivate: [LoginGuard] },
    { path: 'login', loadComponent: () => import('src/shared/components/authentication/login/login.component').then(x => x.LoginComponent), title: 'Joii PMS - Login' },
    { path: 'register', loadComponent: () => import('src/shared/components/authentication/register/register.component').then(x => x.RegisterComponent), title: 'Joii PMS - Register' },
    { path: 'forgottenpassword', loadComponent: () => import('src/shared/components/authentication/forgottenpassword/forgottenpassword.component').then(x => x.ForgottenPasswordComponent), title: 'Joii PMS - Forgotten Password' },
    { path: 'tenants/:redirectUrl', loadComponent: () => import('src/shared/components/authentication/tenants/tenants.component').then(x => x.TenantsComponent) },
    { path: 'unauthorised', loadComponent: () => import('src/shared/components/notauthorised/notauthorised.component').then(x => x.NotAuthorisedComponent) },
    { path: '', redirectTo: "checkaccess", pathMatch: "full" },
    {
        path: '',
        children: [
            ...onboardingRoutes,
            ...dashboardRoutes,
            ...consultationRoutes,
            ...calendarRoutes,
            ...appointmentsRoutes,
            ...accountRoutes
        ]
    },
    { path: '**', redirectTo: "notfound" },
    { path: 'notfound', loadComponent: () => import('src/shared/components/notfound/notfound.component').then(x => x.NotFoundComponent), title: 'Joii PMS - Page Not Found' },
];