import { Component, OnDestroy, effect, inject, untracked } from '@angular/core';
import { Firestore, Timestamp, doc, setDoc } from '@angular/fire/firestore';
import { Subscription, catchError, exhaustMap, from, interval, of, startWith, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StaffProfileStore } from 'src/pms/stores/staff-profile/staff-profile.store';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

interface StaffOnlineData {
  id: string;
  name: string;
  description: string;
  last_online: Timestamp;
  environment: string;
  photoUrl?: string;
}

/**
 * Component to update the staff online status in Firestore.
 */
@Component({
  selector: 'app-staffonlinewrite',
  templateUrl: './staffonlinewrite.component.html',
  standalone: true,
})

export class StaffOnlineWriteComponent implements OnDestroy {
  private authenticationService = inject(AuthenticationService);
  private staffProfileStore = inject(StaffProfileStore);
  private firestore: Firestore = inject(Firestore);
  readonly staffProfile = this.staffProfileStore.profile;
  readonly intervalToSayIAMOnline = 15000; // 15 seconds
  private readonly collection = 'staffonline';

  updateSubscription!: Subscription;

  /**
   * Start updating the staff online status when the staff profile is loaded and the user is logged in.
   * Stop updating when the profile is not loaded or the user is not logged in.
   */
  constructor() {
    effect(() => {
      const profile = this.staffProfileStore.profile();
      const loggedIn = this.authenticationService.loggedIn();

      if (profile && loggedIn) {
        untracked(() => this.updateOnlineStatus());
      } else {
        this.updateSubscription?.unsubscribe();
      }
    })
  }
  /**
   * Unsubscribe from the update subscription when the component is destroyed.
   */
  ngOnDestroy() {
    if (this.updateSubscription) this.updateSubscription.unsubscribe();
  }
  /**
   * Update the staff online status in Firestore every 15 seconds.
   */
  updateOnlineStatus() {
    if (this.updateSubscription) this.updateSubscription.unsubscribe();
    this.updateSubscription = interval(this.intervalToSayIAMOnline).pipe(
      startWith(0), // Start immediately
      exhaustMap(() => this.updateFirestoreStaffOnlineStatus()),
      catchError(error => {
        console.error('Error updating staff online status:', error);
        return of();
      })
    ).subscribe();
  }

  updateFirestoreStaffOnlineStatus() {
    const staffRef = doc(this.firestore, this.collection, this.staffProfile()?.id || '');
    const payload: StaffOnlineData = {
      id: this.staffProfile()?.id ?? '',
      name: this.staffProfile()?.name ?? 'unknown',
      description: this.staffProfile()?.description ?? 'unknown',
      last_online: Timestamp.fromDate(new Date(Date.now())),
      environment: environment.environment,
      photoUrl: this.staffProfile()?.photo_url ?? '',
    }
    return from(setDoc(staffRef, payload, { merge: true }));
  }
}

