import { Component, inject } from '@angular/core';
import { VetAIHeader, VetAIHeaderItemType } from 'src/shared/components/design/vetai-header/vetai-header.interface';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { environment } from '../environments/environment';
import { StaffProfileStore } from './stores/staff-profile/staff-profile.store';
import { VetAIHeaderComponent } from 'src/shared/components/design/vetai-header/vetai-header.component';
import { RouterOutlet } from '@angular/router';
import { initTranslations } from 'src/shared/translate/custom.translate.loader';
import { StaffOnlineWriteComponent } from 'src/shared/components/staffonline/staffonlinewrite/staffonlinewrite.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './pms.component.html',
  providers: [StaffProfileStore],
  imports: [RouterOutlet, VetAIHeaderComponent, StaffOnlineWriteComponent, NgClass],
  standalone: true,
})
export class PMSComponent {
  darkModeService: DarkModeService = inject(DarkModeService)
  menu: VetAIHeader = {
    hiddenpaths: ['/login', '/register', '/unauthorised', '/forgottenpassword', '/notfound', '/checkaccess', '/consultation/', '/onboarding/staff'],
    logo: 'https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9',
    accountpath: '/account/staffprofile',
    items: [
      {
        id: '/dashboard',
        title: 'Home',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionHomeOutline",
        dataautomationid: "pms-sidebar-dashboard",
        hidden: false
      },
      {
        id: '/history',
        title: 'History',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionTimeOutline",
        dataautomationid: "pms-sidebar-consultation-history",
        hidden: false
      },
      {
        id: '/calendar',
        title: 'Calendar',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionCalendarOutline",
        dataautomationid: "pms-sidebar-calendar",
        hidden: false
      },
      {
        id: 'token-copier',
        title: 'Copy Auth',
        type: VetAIHeaderItemType.TokenCopier,
        requiredroles: [],
        icon: "ionClipboardOutline",
        dataautomationid: "pms-sidebar-token-copier",
        hidden: false
      },
    ]
  }

  constructor() { 
    // Initialize translations
    initTranslations();

    if (environment.production) {
      this.loadHotjar();
    }
  }

  private loadHotjar() {
    try {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = ` (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5337804,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      // Add to head
      document.head.appendChild(s);
    } catch (error) {
      console.error('Failed to load Hotjar');
    }
  }
}
