import premium from '@formio/premium';
import tailwind from "@tsed/tailwind-formio";
import * as Sentry from "@sentry/angular";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { Formio } from '@formio/angular';
import { PMSComponent } from './pms/pms.component';
import { pmsConfig } from './pms/pms.config';
import { bootstrapApplication } from '@angular/platform-browser';

Formio.license = 'eyJhbGciOiJQUzI1NiJ9.eyJsaWNlbnNlTmFtZSI6IlZFVCBBSSBMaWJyYXJ5IExpY2Vuc2UgdGhyb3VnaCAwMS8xOC8yMDI2IiwiaWF0IjoxNzM5NTA5MjAwLCJleHAiOjE3Njg3NTU2MDAsImZhaWxEYXRlIjoxNzcyMjk4MDAwLCJ0ZXJtcyI6eyJwcmVtaXVtIjp0cnVlLCJvZmZsaW5lIjp0cnVlLCJlbnRlcnByaXNlQnVpbGRlciI6ZmFsc2UsInJlcG9ydGluZyI6ZmFsc2UsInNxbGNvbm5lY3RvciI6ZmFsc2UsImVuZHBvaW50cyI6WyIqLnZldC1haS5jb20iLCIqLmpvaWlwZXRjYXJlLmNvbSIsIioudmVyY2VsLmFwcCJdLCJob3N0bmFtZXMiOlsiKi52ZXQtYWkuY29tIiwiKi5qb2lpcGV0Y2FyZS5jb20iLCIqLnZlcmNlbC5hcHAiXX0sImlzcyI6Imh0dHBzOi8vZm9ybS5pbyIsInN1YiI6IkZvcm0uaW8ifQ.sjqpzSrPcRvhX4gs04FW16QLMZVZ3DMjc42gOKUkXsQcuMf-8Ntu8w3co_V2f3_ZvzPkywz-i1pvOUt_DFNxgNWKhM_CIgZLG7UJoy6043AdZRx23fHd-EwSTdrqDtesUkWeECuBGYl9gQ6CjZwYg9fzg4xXplB61SL2zkaWCXev3bpgqCg6YyxMIrRmbS5XqwlC3Zuz-0jg0vtL8hy3MRX5XH2LhuuowrBVDzOJw6H3zkBFjoY4YoOgrkG6Fjttm1YRpwMZe7JngsEtaffKo1vsX23x1Ttjm3RsDyrpC_2qLpPswmkK1oQVWCrDgbD1Po9i6RCiFtLGZPynvvQKGw';
Formio.use(premium);
Formio.use(tailwind);
(Formio as any).icons = 'ionicons';
if (environment.production) {
  Sentry.init({
    dsn: "https://630d919edd9a086c3bcd47921e9dae95@o4506773953773568.ingest.sentry.io/4506773993422848",
    release: `joii@${environment.VERSION}`,
    dist: '1',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    environment: environment.environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    profilesSampleRate: 1.0
  });

  enableProdMode();
}

bootstrapApplication(PMSComponent, pmsConfig)
  .catch((err) => console.error(err));
