import { effect, inject } from "@angular/core";
import { tapResponse } from "@ngrx/operators";
import { patchState, signalStore, withHooks, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, pipe } from "rxjs";
import { withAuthStateReset } from "src/app/stores/features/auth-reset/auth-reset.feature";
import { StaffProfileDataService } from "src/pms/services/staffprofiles.data.service/staffprofiles.data.service";
import { AuthenticationService } from "src/shared/services/auth/auth_service";
import { bookinginternal } from "src/shared/services/client/client";
import { ClientErrorMessage } from "src/shared/services/client/clienterror.interface";


type ChatAuditState = {
    triageId: string,
    clientId: string,
    messages: bookinginternal.ChatAuditMessage[],
    users: any[],
    loading: boolean,
    error: ClientErrorMessage | undefined
}

type StaffProfileState = {
    profile: bookinginternal.GetStaffProfileResponse | null,
    onboarding: boolean,
    loading: boolean
    error: ClientErrorMessage | undefined
}

const initialState: StaffProfileState = {
    profile: null,
    onboarding: false,
    loading: false,
    error: undefined
}

export const StaffProfileStore = signalStore(
    withState(initialState),
    withAuthStateReset(initialState),
    withMethods((
        store,
        staffProfileDataService = inject(StaffProfileDataService)
    ) => {
        const setStaffProfile = rxMethod<void>(pipe(exhaustMap(() => staffProfileDataService.GetMyStaffProfile().pipe(
            tapResponse({
                next: (profile) => {
                    patchState(store, { profile, onboarding: false });
                },
                error: (error: any) => {
                    if (error?.code === "failed_precondition") {
                        patchState(store, { onboarding: true });
                    }
                    patchState(store, { error });
                    console.error('Error loading staff profile', error);
                },
                finalize: () => patchState(store, { loading: false })
            })
        ))));
        return { setStaffProfile };
    }),
    withHooks({
        onInit: (store, auth = inject(AuthenticationService)) => {
            effect(() => {
                const loggedIn = auth.loggedIn();
                if (loggedIn) store.setStaffProfile(); 
            }, { allowSignalWrites: true });
        }
    })
);

